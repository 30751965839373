/* Made with love by Mutiullah Samim*/

.container{
height: 100%;
align-content: center;
	position: inherit;
}

.card{
	margin-top: auto;
	margin-bottom: auto;
	width: 400px;
	margin: auto;
	position: inherit !important;
}

.input-group {
	position: inherit !important;	
}

.input-group-prepend span{
	width: 50px;
	background-color: #FFC312;
	color: black;
	border:0 !important;
}

.login_btn{
	color: black;
	background-color: #FFC312;
	width: 100px;
}