.logoHeader {
    height: 100%;
    padding: 5px;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.top-bar {
    top: 0px;
    width: 100%;
    position: fixed;
    min-width: auto;
    box-shadow: var(--bs-sm);
    height: 80px;
    display: flex;
    background-color: #F4976c;
    margin-bottom: 10px;
    border: 0px solid #ebebeb;
    border-bottom: 1px; 
    border-bottom: 1px solid rgba(var(--b6a,219,219,219),1);
    z-index: 1;
}

.siteIcon {

}

.accountActions {
  flex-shrink: 0;
  display: flex;
  position: absolute;
  right: 5px;
}

.accountActions button {
  height: 80%;
  width: 48%;
  min-width: 80px;
  min-height: 63px;
  margin-top: 7px;
  margin-right: 2%;
}

.btn-feedback {
  position: fixed;
  top: 100px;
  left: 10px;
  box-shadow: 4px 5px 12px;
  color: black !important;
  width: 70px !important;
  font-size: 10px !important;
}

.discoverItemButton {
  margin: 10px;
  position: absolute;
  right: 80px;
  top: 7px;
}