
html, body {  
  min-height: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

body {
  background: linear-gradient(182deg, #fae8a6, #775d01) !important;
}

h1 {
  padding-left: 5px;
}

.inputHeader {
  text-align: center;
  font-size: 2.25em;
  margin-bottom: 10px;
  width: 100%;
}

.button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 100%;
}

label {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: .5rem;
  margin-bottom: 0px !important;
}

input {
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  padding: 14px 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  font-size: 14px;
  background-color: lightgrey;
  border-color: #8e8b8b;
}

.btn > a {
  color: white;
}

.votedList {
  padding-top: 40px;
}

.rankList {
  margin: auto;
  width: 98%;
  max-width: 770px;
  padding-bottom: 40px;
}

.userInfo {
  text-align: right;
  margin: auto;
  padding: 10px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.right .bm-burger-button {
    left: initial;
    right: 36px;
}

.logo {
  height: 100%;
}

input[type="radio"] {
  margin: 5px;
}

.form-group input {
  width: 100% !important;
}

.influencerSection {
  margin-top: 25px;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.btn.btn-myRankView.createListButton {
  margin-top: 25px;
  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;
  background: linear-gradient(270deg, #27fc00, #5e26d8);
  margin: 10px;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size: 20px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #FFF;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  width: 200px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  cursor: pointer;
  display: inline-block;
  border-radius: 25px;
  height: 100%;
}

.btn.btn-myRankView {
  background-color: #d2fdff;
  color: black;
  border: black;
}

.featured-section {
  margin-top: 40px;
}

.myLine {
    border: 0px;
    border-top: 1px;
    border-style: solid;
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 20px;
}

.featureHeader {
  width: 200px;
  background-color: #f4976b;
    border: 1px;
    border-style: solid;
    position: absolute;
    left: 50%;
    margin-left: -120px;

    border-radius: 5px;
}

.content {
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logo.large {
  margin-bottom: 20px;
}


.read_view .rankItem {
  padding: 16px;
    margin: 0px 0px 8px;
    background: white;
  box-shadow: 4px 5px 12px;
}

.bm-overlay {
  position: fixed !important;
  left: 0;
  right: 0;
}

.beta-badge {
  margin-left: 5px;
  padding: 5px;
  background-color: blue;
  border-radius: 5px;
  color: white;
}

.logo-1 {
  max-width:100%;
  max-height:100%;
  padding-bottom: 30px;
}