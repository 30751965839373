.RankListPreviews {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
}

.rankListPreview {
	background-color: white;
	width: 30%;
	min-width: 250px;
	max-width: 400px;
	height: 300px;
	padding: 10px;
	margin: 10px;
	display: block;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	box-shadow: 4px 5px 12px;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.rankListName {
	background-color: #ffffffa8;
    box-shadow: 4px 5px 12px;
    border-radius: 4px;
}

.rankListPreview img {
	width: 100%;
	height: 100%;
}

.rankListPreviewActions {
	width: 100%;
}

.rankListPreviewAction > a {
	color: white;
}

.rankListPreviewAction {
	margin: 1px;
	border-radius: 4px;
	color: white;
	height: 50px;
	margin-bottom: 5px;
}

.rankListPreviewHero {
	text-align: center;
}

.influencerSection {
	padding-bottom: 10px;
}