
.rankItemHeader {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rankItemSubDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .75em;
  padding-top: 10px;
  border-top: 1px solid #efeff0;
}


.rankItemDescription {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.rankItemDescription img {
  max-height: 150px;
}

.rankItemsLinks svg {
  padding-right: 5px;
}

.rankItem {
}

.rankItemParent {
  box-shadow: 4px 5px 12px;
}

.insta {

}

.insta img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  animation-name: imageGrow;
  animation-duration: 4s;
  max-height: 500px;
}

 .read-more-button {
  color: blue;
 }

 .aRankItem {
  transition: height 2s;
 }

@keyframes imageGrow {
  from {
    opacity:0;
    height: 0%;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}

.btn.imageUploadBtn {
  height: 150px;
  text-align: center;
  padding: 20px;
}

.rankItemDescriptionEdit {
}

.thumbnailImage {
  overflow: hidden;
}

.thumbnailImage > img{
  height: 150px;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.removeMedia {
  position: absolute;
  top: 0px;
  right: 0px;
}