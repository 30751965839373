.RankItemEditContainer {
	display: flex;
	flex-wrap: wrap;
}

.form-group {
  padding: 10px 10px;
}

.form-group input {
	width: 90%;
}

.rankItemPriceEditor {
	display: block;
	width: 100%;
    text-align: center;
}

.priceSelected {
	font-weight: bold;
    font-size: 50px;
    cursor: pointer;
}

.priceNotSelected {
	font-weight: bold;
    font-size: 50px;
    color: #afafaf;
    cursor: pointer;
}

.rankItemDescription {
    width: 100%;
}

.rankItemActions {
  float: right;
}

.rankItemActions > button {
  margin:  4px;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.editBottom {
}