.bottom-bar {
    bottom: 0px;
    left: 0px;
    width: 100%;
    position: fixed;
    min-width: auto;
    box-shadow: var(--bs-sm);
    height: 40px;
    display: flex;
    background-color: #F4976c;
    margin-top: 10px;
    border: 0px solid #ebebeb;
    border-bottom: 1px; 
    border-bottom: 1px solid rgba(var(--b6a,219,219,219),1);
    z-index: 1;
}

.bottom-bar>span {
    margin: 5px;
}

.re-rank{
}

@-webkit-keyframes Sparkle {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes Sparkle {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes Sparkle {
    0%{background-position:0% 50%}
    50%{background-position:0% 1000%}
    100%{background-position:0% 50%}
}

.btn.re-rankAnimate {
    -webkit-animation: Sparkle 4s ease infinite;
    -moz-animation: Sparkle 4s ease infinite;
    animation: Sparkle 4s ease infinite;
    background: linear-gradient(0deg, #b7003b, #15ff27);
}

.heart {
    color: white;
}

.heart_selected {
    color: red;
}

.vistorActionsContainer {
    position: fixed;
    right: 10px;
    bottom: 15px;
    width: 10%;
}

.reRankContainer {
    width: 100%;
    margin-bottom: 5px;
}

.followContainer {
    width: 100%;
}

@media only screen and (max-width: 1026px) {
    .showVisitorLabel{
        display: none;
    }

    .vistorActionsContainer {
        width: 50px;
    }
}

.showVisitorLabel {
    padding: 5px;
}